import React from 'react'
import { Link } from 'gatsby'

import './ViveVideosStacked.scss'

const ProEye_Horizontal = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProEye_Horizontal.svg'

/**
 *
 * This component shows two html5 videos stacked on top of each other
 * It's meant to show the user a comparison between a headset with and without eye tracking
 * (Although this can be generalized later, for now the video titles are hardcoded)
 *
 * There's an issue with keeping these two videos synced. After scrolling around
 * the page and looking at the videos again, you'll notice they get out of sync.
 *
 * This might just be an impossible task, see:
 * https://stackoverflow.com/questions/20683744/how-to-perfectly-sync-two-or-more-html5-video-tags?rq=1
 *
 * Quick fix:
 * Instead of using the loop attribute to loop the videos,
 * ```
 * <video
 *   loop
 * />
 * ```
 * now I'm just restarting both videos manually, when video2 ends
 * <video
 *   onEnded={() => {
 *     this.video1.pause();
 *     this.video1.currentTime = 0;
 *     this.video1.play();
 *     this.video2.pause();
 *     this.video2.currentTime = 0;
 *     this.video2.play();
 *   }}
 * />
 *
 * This might have performance issues on slower browsers? Might be the best fix?
 *
 * Open to ideas later - Kevin 3/3/20
 */

export default class ViveVideosStacked extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      video1Ready: false,
      video2Ready: false,
      videoLoading: true
    }

    // video elements
    this.video1 = null
    this.video2 = null
  }

  // componentWillUpdate() {

  // }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate', new Date());
    if (this.state.video1Ready && this.state.video2Ready) {
      // console.log('componentDidUpdate video1Ready true && video2Ready true', new Date());
      // console.log('this.video1', this.video1);
      // console.log('this.video2', this.video2);
      this.video1.play()
      // .then(res => {
      //   console.log('this.video1.play success', res);
      // })
      // .catch(err => {
      //   console.log('this.video1.play error', err);
      // })

      this.video2.play()
      // .then(res => {
      //   console.log('this.video2.play success', res);
      // })
      // .catch(err => {
      //   console.log('this.video2.play error', err);
      // })
    }
  }

  render() {
    // console.log('renderrrr')
    return (
      <div className="vive-videos">
        <h2>No Eye Tracking</h2>
        {!this.state.video1Ready || !this.state.video2Ready ? (
          <div className="loader">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        ) : null}
        <video
          className="video" // d-md-none
          // loop
          autoPlay="autoplay" // todo remove
          poster={this.props.video1Poster}
          muted
          playsInline
          // muted={true}

          ref={(element) => {
            // console.log('ref 1', new Date());
            this.video1 = element
          }}
          onCanPlayThrough={() => {
            if (!this.state.video1Ready) {
              this.setState({
                video1Ready: true
              })
              // console.log('onCanPlayThrough video1Ready true', new Date());
            }
          }}
        >
          <source src={this.props.video1Url} type="video/mp4" />
          <img src={this.props.video1Poster} title="Your browser does not support the <video> tag" />
        </video>
        <img className="vive-image" src={ProEye_Horizontal} />
        <video
          className="video" // d-none d-md-block
          // loop
          autoPlay="autoplay" // todo: remove
          poster={this.props.video2Poster}
          muted
          playsInline
          // muted={true}
          ref={(element) => {
            // console.log('ref 2', new Date());
            this.video2 = element
          }}
          onCanPlayThrough={() => {
            if (!this.state.video2Ready) {
              this.setState({
                video2Ready: true
              })
              // console.log('onCanPlayThrough video2Ready true', new Date());
            }
          }}
          /**
           * For some reason, using <video loop> causes the videos to get out of sync
           * (Probably because of how browsers manage resources/streams)
           *
           * For now, manually restarting both videos, when video2 ends, seems to keep
           * the videos in sync perfectly.
           */
          onEnded={() => {
            console.log('video 22222 on ended!')
            this.video1.pause()
            this.video1.currentTime = 0
            this.video1.play()
            this.video2.pause()
            this.video2.currentTime = 0
            this.video2.play()
          }}
        >
          <source src={this.props.video2Url} type="video/mp4" />
          <img src={this.props.video2Poster} title="Your browser does not support the <video> tag" />
        </video>
      </div>
    )
  }
}
