import React from 'react'
import './ViveHeatmap.scss'
import ViveEyeTrackingVideo from '../ViveEyeTrackingVideo/ViveEyeTrackingVideo'

const ViveHeatmap = () => {
  return (
    <div className="wrapper vive-heatmap">
      <div className="row vive-heatmap-body">
        <div className="col-md-6 vive-heatmap-text-section order-md-2">
          <h2>Previously Impossible Training Tools</h2>
          {/* <h2>Enhanced Guidance & Heatmapping</h2> */}
          <div>
            Master the art of keeping your attention on the audience, instead of your notes.
            <br />
            <br />
            Ovation gently reminds you to shift your gaze when it lingers too long. Made possible by eye tracking so
            accurate that it knows what line you’re reading on a teleprompter 15 feet away.
            {/* VIVE Pro Eye's extreme accuracy enables Ovation to determine when you are looking too long at your speech tools, whether a notecard in your hand or a teleprompter 20 feet away. */}
            {/* VIVE Pro Eye elevates Ovation’s gaze guidance and heatmap features by <b>measuring where your eyes are looking with pinpoint accuracy.</b> This means precise readings. Quantifiable metrics. Your exact weaknesses and strengths, clearly illustrated.  */}
          </div>
          {/* <div style={{ flex: "1" }}></div> */}
        </div>
        <div className="col-md-6 order-md-1">
          {/* <img src={heatmapImg} alt="VR heatmap of gaze when speaking" /> */}
          <ViveEyeTrackingVideo showLineDescription={true} />
        </div>
      </div>
    </div>
  )
}

export default ViveHeatmap
