import React from 'react'

import './ViveProductDetails.scss'

const maskIcon = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProductDetails/mask.svg'
const audioIcon = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProductDetails/audio.svg'
const boxIcon = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProductDetails/box.svg'
const featherIcon = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProductDetails/feather.svg'

const ViveProductDetails = () => {
  return (
    <div className="vive-product-details wrapper">
      <div className="row vive-details-row vive-first-row">
        <div className="vive-details-box">
          <div className="vive-details-box-top">
            <div className="vive-icon">
              <img src={maskIcon} alt="VR headset icon" />
            </div>
            <div className="vive-box-top-divider">
              <div className="vive-top-text">2880 x 1600 Combined Resolution.</div>
              <div className="vive-bottom-text">Premium visual fidelity.</div>
            </div>
          </div>
          <div className="vive-box-bottom">
            Deliver breathtaking graphics, texts, and textures in simulations. Allow users to dive into the deepest of
            details with dual-OLED displays with a combined resolution of 2880 x 1600 pixels and 615 PPI.
          </div>
        </div>
        <div className="vive-details-box">
          <div className="vive-details-box-top">
            <div className="vive-icon">
              <img src={audioIcon} alt="VR headset icon" />
            </div>
            <div className="vive-box-top-divider">
              <div className="vive-top-text">Hi Res&#174; & Surround-Sound Audio.</div>
              <div className="vive-bottom-text">Add essential audible cues, context & meaning.</div>
            </div>
          </div>
          <div className="vive-box-bottom">
            Improve the quality of virtual experiences with headphones that mimic the volumes, pitches, and
            reverberations of the real world. Create the immersion required to ensure simulations have a lasting impact.
          </div>
        </div>
      </div>
      <div className="row vive-details-row vive-last-row">
        <div className="vive-details-box">
          <div className="vive-details-box-top">
            <div className="vive-icon">
              <img src={featherIcon} alt="VR headset icon" />
            </div>
            <div className="vive-box-top-divider">
              <div className="vive-top-text">Ready for Extended Sessions in VR.</div>
              <div className="vive-bottom-text">Designed for comfort & balance.</div>
            </div>
          </div>
          <div className="vive-box-bottom">
            Accommodate everyone's comfort preferences. HMD is engineered top-down for easy on and off, quick
            adjustment, and prolonged use. Fits a wide range of head sizes. Minimizes eye fatigue with adjustable
            optics. Suits almost all vision types. Even glasses.
          </div>
        </div>
        <div className="vive-details-box">
          <div className="vive-details-box-top">
            <div className="vive-icon vive-box-icon">
              <img src={boxIcon} alt="VR headset icon" />
            </div>
            <div className="vive-box-top-divider">
              <div className="vive-top-text">32' by 32' Room-Scale Capabilities.*</div>
              <div className="vive-bottom-text">Create expansive multi-user paradigms.</div>
            </div>
          </div>
          <div className="vive-box-bottom">
            Design occlusion-free, wide-area experiences with unmatched tracking accuracy via SteamVR&#8482; 2.0. Scale
            your tracking volumes from seated to standing to full 32' x 32' room-scale for multiple users.
            <div className="vive-fine-print">
              *VIVE Pro Eye is capable of supporting up to a 32' x 32' area using four SteamVR Base Station 2.0 Units.
              With the included two SteamVR Base Station 2.0 Units, it will support up to a 22'11" x 22'11" area. With
              two SteamVR Base Station 1.0 Units, it will support a 11'5" x 11'5" area.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViveProductDetails
