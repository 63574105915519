import React from 'react'

import './ViveSpecs.scss'

const angleUp = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/angle-up.svg'
const angleDown = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/angle-down.svg'

let vrSystemIncludesViewed = false,
  proEyeSpecsViewed = false,
  minComputerSpecsViewed = false

export default class ViveSpecs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sec1open: false,
      sec2open: false,
      sec3open: false
    }
  }

  renderBottomSection(secNum, isOpen) {
    if (isOpen) {
      if (secNum === 1) {
        return (
          <div className="vive-spec-bottom-wrap">
            <div className="vive-spec-bottom">
              <ul className="vive-spec-list list1">
                <li>Base Station 2.0 x 2</li>
                <li>Base Station power adapter x 2</li>
                <li>Link box</li>
                <li>Mounting kit</li>
                <li>USB 3.0 cable</li>
                <li>DisplayPort&#8482; cable</li>
                <li>Link box power adapter</li>
              </ul>
              <ul className="vive-spec-list list2">
                <li>Headset with headset cable</li>
                <li>Cleaning cloth</li>
                <li>Earphone hole cap x 2</li>
                <li>Documentation</li>
                <li>Controller (2018) (with lanyard) x 2</li>
                <li>Power adapter x 2</li>
                <li>Micro-USB x 2</li>
              </ul>
            </div>
          </div>
        )
      } else if (secNum === 2) {
        return (
          <div className="vive-spec-bottom-wrap">
            <div className="vive-spec-bottom vive-spec-bottom-second">
              <div className="vive-spec-list list1">
                <div className="vive-spec-table-title">VIVE Pro Eye Headset</div>
                <table className="vive-spec-table">
                  <tbody>
                    <tr>
                      <td>Screen:</td>
                      <td>Dual OLED 3.5" diagonal</td>
                    </tr>
                    <tr>
                      <td>Refresh rate:</td>
                      <td>90 Hz</td>
                    </tr>
                    <tr>
                      <td>Field of view:</td>
                      <td>110 degrees</td>
                    </tr>
                    <tr>
                      <td>Audio:</td>
                      <td>
                        Hi-Res-certified headset
                        <br />
                        Hi-Res-certificate headphone (removable)
                        <br />
                        High-impedance headphone support
                        <br />
                        Enhanced headphone ergonomics
                      </td>
                    </tr>
                    <tr>
                      <td>Input:</td>
                      <td>Dual Integrated microphones</td>
                    </tr>
                    <tr>
                      <td>Connections:</td>
                      <td>USB-C 3.0, DP 1.2, Bluetooth</td>
                    </tr>
                    <tr>
                      <td>Sensors:</td>
                      <td>SteamVR Tracking, G-sensor, gyroscope, proximity, IPD sensor, eye tracking</td>
                    </tr>
                    <tr>
                      <td>Ergonomics:</td>
                      <td>
                        Eye relief with lens distance adjustment
                        <br />
                        Adjustable IPD
                        <br />
                        Adjustable headphone
                        <br />
                        Adjustable headstrap
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="vive-spec-footnotes">
                  *VIVE Pro Eye is capable of supporting up to a 32' x 32' area using four SteamVR Base Station 2.0
                  Units. With the included two SteamVR Base Station 2.0 Units, it will support up to a 22’11” x 22’11”
                  area. With two SteamVR Base Station 1.0 Units, it will support a 11’5” x 11’5” area.
                </div>
              </div>
              <div className="vive-spec-list list2">
                <div className="vive-spec-table-title">Eye Tracking Specs</div>
                <table className="vive-spec-table">
                  <tbody>
                    <tr>
                      <td>Gaze data output frequency (binocular):</td>
                      <td>120Hz</td>
                    </tr>
                    <tr>
                      <td>Accuracy*:</td>
                      <td>0.5°–1.1°</td>
                    </tr>
                    <tr>
                      <td>Calibration:</td>
                      <td>5-point</td>
                    </tr>
                    <tr>
                      <td>Trackable field of view**:</td>
                      <td>110°</td>
                    </tr>
                    <tr>
                      <td>Data output (eye information):</td>
                      <td>
                        Timestamp (device and system)
                        <br />
                        Gaze origin
                        <br />
                        Gaze direction
                        <br />
                        Pupil position
                        <br />
                        Pupil size
                        <br />
                        Eye openness
                      </td>
                    </tr>
                    <tr>
                      <td>Interface:</td>
                      <td>HTC SRanipal SDK</td>
                    </tr>
                    <tr>
                      <td>SDK engine compatibility:</td>
                      <td>Unity, Unreal</td>
                    </tr>
                  </tbody>
                </table>
                <div className="vive-spec-footnotes">
                  *Within FOV 20°
                  <br />
                  **Eye surgery, eye disease, heavy makeup, and high myopia may affect eye tracking performance
                </div>
              </div>
            </div>
            <div className="vive-spec-bottom vive-spec-bottom-second">
              <div className="vive-spec-list list1">
                <div className="vive-spec-table-title">Controller Specs</div>
                <table className="vive-spec-table">
                  <tbody>
                    <tr>
                      <td>Sensors:</td>
                      <td>SteamVR Tracking 2.0</td>
                    </tr>
                    <tr>
                      <td>Input:</td>
                      <td>Multifunction trackpad, Grip buttons, Dual-stage trigger, System button, Menu button</td>
                    </tr>
                    <tr>
                      <td>Connections:</td>
                      <td>Micro-USB charging port</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="vive-spec-list list2">
                <div className="vive-spec-table-title">Tracked Area Requirements</div>
                <table className="vive-spec-table">
                  <tbody>
                    <tr>
                      <td>Standing / Seated:</td>
                      <td>No min. space requirements</td>
                    </tr>
                    <tr>
                      <td>Room-scale:</td>
                      <td>
                        A minimum play of 6' 6 x 4' 11 is required. Maximum size is 32' x 32' with 4 SteamVR 2.0 Base
                        Stations.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      } else if (secNum === 3) {
        return (
          <div className="vive-spec-bottom-wrap">
            <div className="vive-spec-bottom vive-spec-bottom-last">
              <div className="vive-spec-list list1">
                <table className="vive-spec-table">
                  <tbody>
                    <tr>
                      <td>Processor:</td>
                      <td>Intel® Core™ i5-4590 or AMD FX™ 8350, equivalent or better.</td>
                    </tr>
                    <tr>
                      <td>Graphics:</td>
                      <td>NVIDIA® GeForce® GTX 970 or AMD Radeon™ R9 290 equivalent or better.</td>
                    </tr>
                    <tr>
                      <td>Memory:</td>
                      <td>4 GB RAM or more</td>
                    </tr>
                    <tr>
                      <td>Video out:</td>
                      <td>DisplayPort 1.2 or newer</td>
                    </tr>
                    <tr>
                      <td>USB ports:</td>
                      <td>1x USB 3.0 or newer port</td>
                    </tr>
                    <tr>
                      <td>Operating system:</td>
                      <td>
                        Windows® 7, Windows® 8.1 or later, Windows® 10
                        <br />
                        Upgrade to Windows® 10 for the best results with the dual front facing cameras
                        <br />* Driver is required to download and install before using VIVE Pro Eye's dual cameras with
                        Windows® 7.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="vive-spec-footnotes">
                  Recommended graphics for the best enterprise VR solution is NVIDIA GeForce GTX 1070/Quadro P5000 or
                  above, or AMD Radeon Vega 56/Pro WX7100/FirePro W9100 or above.
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderArrow(isOpen) {
    if (isOpen) {
      return <img className="vive-spec-arrow" src={angleUp} alt="arrow" />
    } else {
      return <img className="vive-spec-arrow" src={angleDown} alt="arrow" />
    }
  }

  toggleSection(secNum) {
    const key = `sec${secNum}open`
    this.setState({
      [key]: !this.state[key]
    })

    // send gtm events, but only once per page view
    window.dataLayer = window.dataLayer || []
    if (secNum === 1 && !vrSystemIncludesViewed) {
      console.log('gtm-interactive-element-fine-print-pro-eye-vr-system-includes')
      window.dataLayer.push({
        event: 'gtm-interactive-element-fine-print-pro-eye-vr-system-includes'
      })
      vrSystemIncludesViewed = true
    } else if (secNum === 2 && !proEyeSpecsViewed) {
      console.log('gtm-interactive-element-fine-print-pro-eye-specs')
      window.dataLayer.push({
        event: 'gtm-interactive-element-fine-print-pro-eye-specs'
      })
      proEyeSpecsViewed = true
    } else if (secNum === 3 && !minComputerSpecsViewed) {
      console.log('gtm-interactive-element-fine-print-minimum-computer-specs')
      window.dataLayer.push({
        event: 'gtm-interactive-element-fine-print-minimum-computer-specs'
      })
      minComputerSpecsViewed = true
    }
  }

  render() {
    return (
      <div className="vive-specs wrapper">
        <div className="vive-spec-item">
          <div className="vive-spec-top" onClick={this.toggleSection.bind(this, 1)}>
            <div className="vive-spec-title">VIVE Pro Eye VR System Includes</div>
            {this.renderArrow(this.state.sec1open)}
          </div>
          {this.renderBottomSection(1, this.state.sec1open)}
        </div>
        <div className="vive-spec-item vive-spec-border">
          <div className="vive-spec-top" onClick={this.toggleSection.bind(this, 2)}>
            <div className="vive-spec-title">VIVE Pro Eye Specs</div>
            {this.renderArrow(this.state.sec2open)}
          </div>
          {this.renderBottomSection(2, this.state.sec2open)}
        </div>
        <div className="vive-spec-item">
          <div className="vive-spec-top" onClick={this.toggleSection.bind(this, 3)}>
            <div className="vive-spec-title">Minimum Computer Specs</div>
            {this.renderArrow(this.state.sec3open)}
          </div>
          {this.renderBottomSection(3, this.state.sec3open)}
        </div>
      </div>
    )
  }
}
