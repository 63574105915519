import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './ViveCaseStudy.scss'

const ViveCaseStudyDownload = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/VIVE_Case_Study_Eye_Tracking_Ovation.pdf'

export default class ViveCaseStudy extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="vive-case-study">
        <div className="wrapper">
          <div className="vive-case-study-text">
            <h2>Explore More Advantages of Eye Tracking</h2>
          </div>
          <div className="vive-case-study-button-wrap">
            <a
              target="_blank"
              href={ViveCaseStudyDownload}
              className="gtm-download-htc-asset-pro-eye-case-study vive-case-study-button"
            >
              Download the Case Study&nbsp;&nbsp;
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </div>
          <div className="vive-case-study-videos-wrap">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="embed-responsive embed-responsive-16by9 vive-case-study-video-first">
                      <iframe
                        src="https://www.youtube.com/embed/H8NpDwmieyE?rel=0"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe src="https://www.youtube.com/embed/tarFEEiLiGM?rel=0" frameBorder="0" allowFullScreen />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
