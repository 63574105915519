import React from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './ViveFooter.scss'

function goToContactUs() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'gtm-site-link-contact-pro-eye-page'
  })
  navigate('/contact')
}

const ViveFooter = () => {
  return (
    <div className="vive-footer">
      <div className="vive-product-btns">
        <a
          href="http://www.kqzyfj.com/click-9181310-13854637"
          target="_blank"
          className="gtm-outbound-link-htc-store-bottom-buy-vive-pro-eye vive-product-btn"
        >
          Buy VIVE Pro Eye&nbsp;&nbsp;
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
        <a style={{ cursor: 'pointer' }} onClick={goToContactUs} className="vive-product-btn">
          Contact Us
        </a>
      </div>
      {/* <div className="vive-footnotes">
        <div>
          *Statistic acquired from glossophobia.com.
        </div>
        <div>
          **VR-ready PC required.
        </div>
      </div> */}
    </div>
  )
}

export default ViveFooter
