import React from 'react'
import Helmet from 'react-helmet'

import ViveHero from '../components/ViveProEye/ViveHero/ViveHero'
import ViveProductHero from '../components/ViveProEye/ViveProductHero/ViveProductHero'
import ViveDataPoints from '../components/ViveProEye/ViveDataPoints/ViveDataPoints'
import ViveHeatmap from '../components/ViveProEye/ViveHeatmap/ViveHeatmap'
import ViveAudience from '../components/ViveProEye/ViveAudience/ViveAudience'
import ViveProductImage from '../components/ViveProEye/ViveProductImage/ViveProductImage'
import ViveProductDetails from '../components/ViveProEye/ViveProductDetails/ViveProductDetails'
import ViveSpecs from '../components/ViveProEye/ViveSpecs/ViveSpecs'
import ViveFooter from '../components/ViveProEye/ViveFooter/ViveFooter'
import ViveCaseStudy from '../components/ViveProEye/ViveCaseStudy/ViveCaseStudy'
import EmailList from '../components/EmailList/EmailList'

let scrolled25PercentYet, scrolled50PercentYet, scrolled75PercentYet, scrolled100PercentYet

class ViveProEye extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    scrolled25PercentYet = false
    scrolled50PercentYet = false
    scrolled75PercentYet = false
    scrolled100PercentYet = false
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

    const scrolledPercentage = winScroll / height // 0 to 1

    window.dataLayer = window.dataLayer || []
    if (scrolledPercentage >= 0.25 && !scrolled25PercentYet) {
      console.log('gtm-behavior-pro-eye-scroll-depth-25')
      window.dataLayer.push({
        event: 'gtm-behavior-pro-eye-scroll-depth-25'
      })
      scrolled25PercentYet = true
    } else if (scrolledPercentage >= 0.5 && !scrolled50PercentYet) {
      console.log('gtm-behavior-pro-eye-scroll-depth-50')
      window.dataLayer.push({
        event: 'gtm-behavior-pro-eye-scroll-depth-50'
      })
      scrolled50PercentYet = true
    } else if (scrolledPercentage >= 0.75 && !scrolled75PercentYet) {
      console.log('gtm-behavior-pro-eye-scroll-depth-75')
      window.dataLayer.push({
        event: 'gtm-behavior-pro-eye-scroll-depth-75'
      })
      scrolled75PercentYet = true
    } else if (scrolledPercentage >= 1 && !scrolled100PercentYet) {
      console.log('gtm-behavior-pro-eye-scroll-depth-100')
      window.dataLayer.push({
        event: 'gtm-behavior-pro-eye-scroll-depth-100'
      })
      scrolled100PercentYet = true
    }
  }

  render() {
    return (
      <div id="viveproeye" style={{ background: '#fff' }}>
        <Helmet title={'Ovation | VIVE Pro Eye VR'}>
          <meta
            name="description"
            content="Practice your public speaking in VR. Ovation provides realistic virtual audiences, tools and feedback to help you grow your skills."
          />
          <meta name="keywords" content="VR, Public, Speaking, Training" />
          {/* HTC's Facebook Pixel Code */}
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js')
              fbq('init', '1109594076054988')
              fbq('track', 'PageView')
            `}
          </script>
          <noscript>
            {`
              <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=1109594076054988&ev=PageView&noscript=1"
              />
            `}
          </noscript>
          {/* End Facebook Pixel Code */}
        </Helmet>
        <ViveHero />
        <ViveProductHero />
        <ViveDataPoints />
        <ViveHeatmap />
        <ViveAudience />
        <ViveCaseStudy />
        <EmailList gtmSubmitEventName="gtm-newsletter-add-contact-pro-eye-page" backgroundColor="#fcfcfc" />
        <ViveProductImage />
        <ViveProductDetails />
        <ViveSpecs />
        <ViveFooter />
      </div>
    )
  }
}

export default ViveProEye
