import React from 'react'

import './ViveProductImage.scss'

const viveProductImg = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/vive-product-footer.png'
const viveLogo = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/vive-logo.png'

const ViveProductImage = () => {
  return (
    <div className="vive-product-image">
      <img className="vive-headset-img" src={viveProductImg} alt="VIVE Pro Eye product" />
      <img className="vive-logo-img" src={viveLogo} alt="VIVE Pro Eye logo" />
      <div className="vive-logo-desc">with Precision Eye Tracking</div>
    </div>
  )
}

export default ViveProductImage
