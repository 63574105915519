import React from 'react'
import './ViveHero.scss'

const heroImg = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProEyeBanner_Desktop2.jpg'
const mobileHeroImg = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ProEyeBanner_Mobile2.jpg'
const xIcon = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/x.svg'

export default class ViveHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoOpen: false,
      width: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  toggleVideo() {
    this.setState({
      videoOpen: !this.state.videoOpen
    })
  }

  renderCta() {
    const hideCtaStyle = {
      display: 'none'
    }
    if (this.state.width > 700) {
    }
  }

  renderMobileCta() {
    if (this.state.width <= 700) {
      return (
        <div className="vive-hero-cta-mobile">
          <div className="vive-hero-cta-text">
            Accelerate improvement with Ovation's advanced eye-tracking features.
          </div>
        </div>
      )
    }
  }

  render() {
    const dynamicHeroImg = this.state.width > 700 ? heroImg : mobileHeroImg
    const heroStyle = {
      backgroundImage: `url(${dynamicHeroImg})`
    }
    const videoStyle = {
      background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(${dynamicHeroImg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }
    const hideCtaStyle = {
      display: 'none'
    }
    return (
      <div className="vive-hero-container">
        <div className="vive-hero" style={this.state.videoOpen ? videoStyle : heroStyle}>
          {this.renderCta()}
          <div className="vive-hero-video" style={this.state.videoOpen ? {} : hideCtaStyle}>
            <div className="vive-hero-iframe">
              <iframe src="https://www.youtube.com/embed/tarFEEiLiGM?rel=0" frameBorder="0" allowFullScreen />
              <div className="vive-video-close-wrap">
                <div className="vive-video-close">
                  <img src={xIcon} onClick={this.toggleVideo.bind(this)} alt="close icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderMobileCta()}
      </div>
    )
  }
}
