import React from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby' // navigateTo is deprecated in gatsby v2, use navigate instead
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './ViveProductHero.scss'

const ViveProductHeroImage = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/vive-product-hero.jpg'

const ViveProductHero = () => {
  return (
    <div className="wrapper">
      <div className="vive-product">
        <div className="vive-product-img">
          <img src={ViveProductHeroImage} alt="VIVE Pro Eye VR system" />
        </div>
        <div className="vive-product-header">
          <div className="vive-product-title">Ovation & VIVE Pro Eye</div>
          <div className="vive-product-subtitle">
            Improve training outcomes.
            <br />
            Better combat speech anxiety.
          </div>
          {/* <div className="vive-product-details">
            <div>
              75% of all people suffer from glossophobia, the fear of public speaking.* 
            </div>
            <div>
              Are you one of these people? Is this fear getting in the way of your personal or professional development? We can help. The <b>Ovation-VIVE Pro Eye VR solution</b> provides public speakers, institutions, and enterprises with one of the most effective speech toolkits available today.** Learn how precision eye tracking technology makes the most of our powerful software.  
            </div>
          </div> */}
          <div className="vive-product-btns">
            <a
              href="http://www.kqzyfj.com/click-9181310-13854637"
              target="_blank"
              className="gtm-outbound-link-htc-store-top-buy-vive-pro-eye vive-product-btn"
            >
              Buy VIVE Pro Eye&nbsp;&nbsp;
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
            {/* <a
              onClick={() =>
                navigate('/pricing')
              }
              className="vive-product-btn"
            >
              Start Your Ovation Trial
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViveProductHero
