import React from 'react'
import TrackVisibility from 'react-on-screen'

import './ViveAudience.scss'
import ViveVideosStacked from '../ViveVideosStacked/ViveVideosStacked'

const ViveAudienceVideo1 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Avatar_Top.mp4'
const ViveAudiencePoster1 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Avatar_Top.jpg'
const ViveAudienceVideo2 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_ET_Avatar_Bottom.mp4'
const ViveAudiencePoster2 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_ET_Avatar_Bottom.jpg'

const ViveAudience = () => {
  return (
    <div className="vive-audience">
      <div className="wrapper vive-wrapper">
        <div className="row vive-audience-body">
          <div className="col-md-6 vive-audience-section">
            <h2>More Expressive Avatars</h2>
            {/* <h2>More Immersive Environments</h2> */}
            <div>
              Watch your performance as you expect it to look, with your playback avatar’s eyes blinking and darting
              around the environment &mdash; just as yours did.
              {/* Breathe life into your avatar. With eye tracking, Ovation's playback avatar blinks and looks around the venue &mdash; just as you did. */}
              {/* Dynamic Audiences is one of Ovation’s signature features. They nod. They smile. They blink. <b>With VIVE Pro Eye, they are more like the real thing.</b> Eye tracking allows us to fine-tune our AI reaction system. Lock eyes with your audience. Watch them react accordingly — and realistically. */}
            </div>
          </div>
          <div className="col-md-6 vive-audience-section">
            {/* <img src={audienceImg} alt="VR dynamic audience" /> */}
            <ViveVideosStacked
              video1Url={ViveAudienceVideo1}
              video1Poster={ViveAudiencePoster1}
              video2Url={ViveAudienceVideo2}
              video2Poster={ViveAudiencePoster2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViveAudience
