import React from 'react'
import attDistImg from '../images/DeeperDataPoints.jpg'
import ViveVideosStacked from '../ViveVideosStacked/ViveVideosStacked'
import './ViveDataPoints.scss'

const ViveDataPointsVideo1 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Top.mp4'
const ViveDataPointsPoster1 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Top.jpg'
const ViveDataPointsVideo2 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_ET_Bottom.mp4'
const ViveDataPointsPoster2 = 'https://d1syj4d8txnu77.cloudfront.net/VivePro/ETvsNot_NoET_Top.jpg'

const ViveDataPoints = () => {
  return (
    <div className="vive-data-points">
      <div className="wrapper vive-wrapper">
        <div className="row vive-data-points-body">
          <div className="col-md-6 vive-data-section">
            <h2>Pinpoint Accuracy</h2>
            <div>
              Most VR headsets only provide information about where your head is pointed. Vive Pro Eye takes that to the
              next level by determining the <b>exact location your eyes are looking</b>, leading to more accurate
              analytics and better insights.
              <br />
              <br />
              In these clips, the speaker looks left and right with their head, but only eye tracking is able to reveal
              how extensively he scanned the crowd.
            </div>
            {/* <div>
              Filler words. Erratic hand movements. Unequal attention distribution. Ovation captures it all. VIVE Pro Eye takes it a step further. It can help you <b>identify nervous, non-verbal, and subconscious habits</b> by measuring factors like eye movement speed, blink rate, and time spent visually interacting with your audience.
            </div> */}
          </div>
          <div className="col-md-6 vive-data-section vive-data-section-img">
            {/* <img src={attDistImg} alt="VR attention distribution data" /> */}
            <ViveVideosStacked
              video1Url={ViveDataPointsVideo1}
              video1Poster={ViveDataPointsPoster1}
              video2Url={ViveDataPointsVideo2}
              video2Poster={ViveDataPointsPoster2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViveDataPoints
